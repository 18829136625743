import React from "react";

import { getSlug } from "utils/formatting";

import Box from "components/Box";
import Image from "components/Image";
import Link from "components/Link";
import VisuallyHidden from "components/VisuallyHidden";

const ArtworkCard = props => {
  const { content } = props;
  const url = getSlug({ _type: "artwork", content });
  return (
    <Box sx={{ position: "relative" }}>
      <Image width={400} {...content.main.image} />
      <Link to={url} variant="cover">
        <VisuallyHidden>{content.main.title}</VisuallyHidden>
      </Link>
    </Box>
  );
};

export default ArtworkCard;
