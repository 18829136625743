import React from "react";
import { get } from "theme-ui";

import ArtworkCard from "components/Card/Artwork";
import Box from "components/Box";
import Heading from "components/Heading";
import Text from "components/Text";

const style = {
  container: {
    // maxWidth: "100%",
    overflow: "hidden",
    overflowX: "auto",
    width: "100vw",
    position: "relative",
    left: "50%",
    right: "50%",
    ml: "-50vw",
    mr: "-50vw",
    mb: 6,
    px: 3,
  },

  row: {
    display: "grid",
    gap: 3,
    px: theme => `calc((100vw - ${get(theme, "sizes.xl")}px) / 2)`,
  },

  item: {
    position: "relative",
  },
};

const defaultProps = {
  minHeight: 300,
};

const ArtworkGrid = ({ artwork, minHeight, title, shortDescription, sx }) => {
  const gridTemplateColumns = artwork.reduce((str, item, i) => {
    const height = !isNaN(Number(minHeight)) ? minHeight + "px" : minHeight;
    const ar =
      item?.content?.main?.image?.asset?.metadata?.dimensions?.aspectRatio || 1;
    const col = `minmax(calc(${ar} * ${height}), ${ar}fr)`;
    return i ? `${str} ${col}` : col;
  }, "");

  return (
    <Box variant="container" sx={sx}>
      {title && (
        <Heading as="h3" sx={{ color: "fuchsia.500", px: 4 }}>
          {title}
        </Heading>
      )}
      {shortDescription && <Text>{shortDescription}</Text>}
      <Box sx={style.container}>
        <Box __css={style.row} style={{ gridTemplateColumns, minHeight }}>
          {artwork.map(piece => (
            <Box key={piece._id} __css={style.item}>
              <ArtworkCard {...piece} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

ArtworkGrid.defaultProps = defaultProps;

export default ArtworkGrid;
